.root {
	background: var(--placeholderBg);
	min-height: 100vh;
	position: relative;
	border-radius: var(--hdsplus-spacing-03);
	overflow: hidden;
	border: 1px solid var(--token-color-border-primary);
}

.registrationForm {
	display: flex; 
	align-items: stretch;
	height: 100%;
	max-width: 100%;
	position: absolute;
	inset: 0;
}

.loading {
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;
	width: 100%;
	padding: var(--hdsplus-spacing-09);

	& .passRow {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (--medium-up) {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	& .fields {
		@media (--medium-up) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: var(--wpl-spacing-07);
		}
	}

	& .submit {
		& > * {
			margin: 0 auto;
		}
	}

	& .desktop {
		@media (--small) {
			display: none;
		}
	}

	& .mobile {
		@media (--medium-up) {
			display: none;
		}
	}
}

