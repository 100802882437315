.box {
	background-color: var(--placeholderBoxBg);
	border-radius: 2px;
	margin-bottom: 12px;
	width: 100%;
	max-width: 100%;
	background-repeat: no-repeat;
	background-image: var(--placeholderBoxGradient);
	background-position: -300% 0;
	background-size: 75% 100%;
	animation: loading 2s infinite;
}

@keyframes loading {
	to {
		background-position: 400% 0;
	}
}

.prose {
	margin: 20px 0;

	& .box {
		height: 1rem;
		margin-bottom: 10px;
		display: block;
	}
}
