.footer {
	background-color: var(--token-color-page-primary);
	padding-top: var(--hdsplus-spacing-11);
	padding-bottom: var(--hdsplus-spacing-11);

	&.hasPrefooter {
		padding-top: var(--hdsplus-spacing-08);

		@media (--large) {
			padding-top: var(--hdsplus-spacing-03);
		}
	}
}

.primaryLinksWrapper {
	composes: g-grid-container from global;
}

.primaryLinks {
	border-bottom: 1px solid var(--token-color-border-faint);
	padding-bottom: var(--hdsplus-spacing-07);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: var(--hdsplus-spacing-07);
	align-items: flex-start;

	@media (--medium-up) {
		padding-bottom: var(--hdsplus-spacing-09);
		column-gap: var(--hdsplus-spacing-11);
	}

	@media (--large) {
		padding-bottom: var(--hdsplus-spacing-10);
		column-gap: var(--hdsplus-spacing-15);
	}

	&.hasPrefooter {
		border-top: 1px solid var(--token-color-border-faint);
		padding-top: var(--hdsplus-spacing-07);

		@media (--large) {
			padding-top: var(--hdsplus-spacing-10);
		}
	}
}

.linkLists {
	display: flex;
	flex-wrap: wrap;
	column-gap: var(--hdsplus-spacing-05);
	row-gap: var(--hdsplus-spacing-08);
	max-height: 1000px;
	width: 100%;
	justify-content: space-between;
	flex-grow: 1;

	@media (--medium-up) {
		column-gap: var(--hdsplus-spacing-07);
		width: auto;
		flex-direction: row;
	}
}

.logoWrapper {
	width: 100%;

	@media (--large) {
		width: auto;
	}
}

.logo {
	height: 35px;
	width: auto;
}

.list {
	flex-direction: column;
	gap: var(--hdsplus-spacing-03);
	display: flex;
	width: calc(50% - var(--hdsplus-spacing-03));

	@media (--medium-up) {
		width: auto;
		max-width: unset;
	}
}

.links {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-04);
}

.linkListItem {
	display: flex;
	align-items: center;
}

.bottom {
	composes: g-grid-container from global;
	display: flex;
	flex-wrap: wrap;
	padding-top: var(--hdsplus-spacing-08);
	row-gap: var(--hdsplus-spacing-08);
	justify-content: space-between;

	@media (--medium-up) {
		padding-top: var(--hdsplus-spacing-07);
		row-gap: var(--hdsplus-spacing-07);
	}
}

.link {
	composes: hds-typography-body-100 from global;
	text-decoration: none;
	color: var(--token-color-foreground-faint);

	&:hover {
		color: var(--token-color-foreground-faint);
	}
}

.legalLinks {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	row-gap: var(--hdsplus-spacing-04);
	column-gap: var(--hdsplus-spacing-05);
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0 auto;
	width: 100%;
	padding-bottom: var(--hdsplus-spacing-08);
	border-bottom: 1px solid var(--token-color-border-faint);

	@media (--medium-up) {
		padding-bottom: 0;
		border-bottom: 0;
		display: flex;
		column-gap: var(--hdsplus-spacing-07);
		justify-content: center;
	}

	@media (--large) {
		margin: 0;
		width: auto;
		justify-content: flex-start;
	}
}

.socialLinks {
	width: 258px;
	margin: 0 auto;

	@media (--large) {
		margin: 0;
	}
}
